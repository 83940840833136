html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

[v-cloak] {
  visibility: hidden !important; }

html, body {
  height: 100%;
  width: 100%;
  font-size: 100%;
  margin: 0; }

body {
  text-rendering: optimizeLegibility; }

html, body, h1, h2, h3, h4, h5, h6, ul, li, p, a, b, i, span, article, figure, header, footer, section, hgroup, fieldset {
  margin: 0;
  padding: 0; }

h1, h2, h3, h4, h5, h6 {
  font-weight: normal; }

li {
  list-style-type: none; }

ol li {
  list-style-type: decimal; }

.ul-default, .ul-default li {
  list-style-type: disc; }

fieldset {
  border: none; }

a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

a img {
  outline: none;
  border: 0; }

object, embed {
  outline: 0; }

img {
  max-width: 100%; }

.u-clearfix:after, .clearfix:after {
  content: "";
  display: table;
  clear: both; }

.hideText {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden; }

.nos {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.resetBtn {
  outline: 0;
  border: 0;
  background: none;
  margin: 0;
  padding: 0;
  font-size: inherit; }

.resetLink {
  text-decoration: none;
  color: inherit; }

.flex {
  display: flex; }

.align-center {
  align-items: center; }

.justify-center {
  justify-content: center; }

.vac {
  height: 100%;
  display: table;
  margin: 0 auto; }

.vac-inner {
  display: table-cell;
  vertical-align: middle; }

.fl {
  float: left; }

.fr {
  float: right; }

.fn {
  float: none; }

.cell {
  display: table-cell;
  vertical-align: middle; }

.db {
  display: block; }

.dib {
  display: inline-block; }

.hide {
  display: none; }

.ninja {
  visibility: hidden; }

.table {
  display: table;
  height: 100%; }

.h-100 {
  height: 100%; }

.m0 {
  margin: 0; }

.m0a {
  margin: 0 auto; }

.ma {
  margin: auto; }

.mh-100 {
  min-height: 100%; }

.noPad {
  padding: 0 !important; }

.inner, .pos-r, .rel {
  position: relative; }

.pos-a {
  position: absolute; }

.h100 {
  height: 100%; }

.w100 {
  width: 100%; }

.nowrap, .no-wrap {
  white-space: nowrap; }

.ttu {
  text-transform: uppercase; }

.tac {
  text-align: center; }

.tar {
  text-align: right; }

.strut {
  display: inline-block;
  height: 1em; }
